* {
    font-family: Roboto;
}

body {
    margin: 0;
}

hr {
    background: #adb0c3;
    height: 2px;
    border:none;
    outline: none;
}
